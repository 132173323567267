// Normalise all the CSS
@import '~modern-normalize/modern-normalize.css';

$breakpoints : (
    'sm' : 576px,
    'md' : 768px,
    'lg' : 992px,
    'xl' : 1200px
) !default;

$colours     : (
    'primary' : #00a27c,
    'yellow' : #f4b30c,
    'red' : #cd1919,
    'green' : #08cf9f,
    'purple' : #5c72b5
);

@mixin respond-to-min($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media(min-width : map-get($breakpoints, $breakpoint)) {
      @content;
    }
  }
}

@mixin respond-to-max($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media(max-width : map-get($breakpoints, $breakpoint)) {
      @content;
    }
  }
}

@mixin colour($colour) {
  @if map-has-key($colours, $colour) {
    color : map-get($colours, $colour);
  }
}

@mixin bg($colour) {
  @if map-has-key($colours, $colour) {
    background-color : map-get($colours, $colour);
  }
}

body {
  background-color : var(--primary-bg);
  color            : var(--primary-text);
  height           : 100%;
  font     : {
    family : 'Inter', sans-serif;
    weight : 400;
  }
}