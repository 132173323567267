.packages {
  margin : {
    top  : 1rem;
    left : 1rem;
  }

  @include respond-to-max('lg') {
    margin-left : 0;
  }

  .package {
    background-color : var(--inverse-bg);
    color            : var(--inverse-text);
    border-radius    : .8rem;
    display          : flex;
    text-decoration  : none;
    flex        : {
      direction : row;
      grow      : 0;
    }
    padding          : 1.6rem;
    justify-content  : flex-start;
    align-items      : center;
    overflow         : hidden;

    & + .package {
      margin-top : 1rem;
    }

    &__name {
      font     : {
        weight : 700;
        size   : 2rem;
      }
      text-align   : center;
      width        : 25%;
      flex     : {
        shrink : 0;
      }
      margin-right : 2rem;

      @include respond-to-max('md') {
        text-align : left;
        width      : unset;
      }
    }

    &__description {
      p {
        &:first-of-type {
          margin-top : 0;
        }
      }
    }

    @include respond-to-max('md') {
      flex        : {
        direction : column;
      }
      align-items : start;
      padding     : 2rem;
    }
  }
}