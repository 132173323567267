.hero {
  margin-top       : 0 !important;

  &__text {
    max-width   : 50%;
    margin   : {
      top    : 3rem;
      bottom : 3rem;
    }
    font     : {
      size   : 1.4rem;
      weight : 400;
    }
    line-height : 2rem;
    order       : 1;

    @include respond-to-max('md') {
      max-width     : 100%;
      margin-bottom : 1rem;
    }

    &-title {
      font     : {
        size   : 3.8rem;
        weight : 700;
      }
      margin   : {
        top    : 0;
        bottom : 1rem;
      }
      line-height : 3.8rem;

      @include respond-to-max('md') {
        margin : {
          top  : 1rem;
        }
      }
    }

    @include respond-to-max('lg') {
      max-width : 100%;
      width     : 100%;
      order     : 2;
    }

    &-controls {
      display         : flex;
      width           : 100%;
      flex        : {
        direction : row;
        shrink    : 0;
        grow      : 0;
      }
      justify-content : start;

      .button:not(:last-child) {
        margin-right : 1.5rem;
      }
    }
  }

  &__image {
    max-width : 50%;
    width     : 50%;
    padding   : 1.5rem;
    margin   : {
      bottom : -7rem;
    }
    overflow  : visible;
    order     : 2;

    @include respond-to-max('sm') {
      display : none;
    }

    @include respond-to-max('lg') {
      max-width : 100%;
      width     : 100%;
      order     : 1;
    }
  }

  @include respond-to-max('sm') {
    padding : 1rem;
  }
}