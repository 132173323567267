.text {
  &--underline {
    text-decoration : underline;
  }

  &--emphasis {
    font-style  : italic;
    font-weight : 400;
  }

  &--center {
    text-align: center;
  }
}

a, a:hover, a:visited, a:link {
  @include colour('primary');
}

@each $name, $colour in $colours {
  .text--#{$name} {
    color : $colour;
  }

  .bg--#{$name} {
    background-color : $colour;
    color            : white;
  }
}